import React, { useState, useEffect, useContext } from 'react';
import { api_base_url } from '../../data/client';
import { DashCommunity } from '../../parts';
import { UserContext } from '../UserContext/UserContext';
import axios from 'axios';

const DashCommunityContainer = () => {
  const { userGlobalState } = useContext(UserContext);

  const [discordData, setDiscordData] = useState({
    isLoading: true,
    hasAccess: false,
    discordUrl: ''
  });

  useEffect(() => {
    const getDiscordPrivateLink = async () => {
      try {
        const {
          data
        } = await axios.post(
          `${api_base_url}/users/discord/private-invite-url`,
          { userId: userGlobalState.user._id }
        );

        setDiscordData({
          isLoading: false,
          hasAccess: true,
          url: data.url
        });
      } catch (err) {
        setDiscordData({ isLoading: false, hasAccess: false, discordUrl: '' });
      }
    };

    getDiscordPrivateLink();
  }, []);

  return <DashCommunity discordData={discordData} />;
};

export default DashCommunityContainer;
