import React from 'react';
import DashboardWrapper from '../../containers/Dashboard/DashboardWrapper';
import DashCommunityContainer from '../../containers/DashCommunityContainer';

const DashCommunityPage = ({ location, pageContext }) => {
  return (
    <DashboardWrapper location={location} pageContext={pageContext}>
      <DashCommunityContainer />
    </DashboardWrapper>
  );
};

export default DashCommunityPage;
